export const transactions = [
  {
    "Icon": "cofee",
    "Date": "04/03/2024",
    "Time": "8:00 AM",
    "Day of the week": "Monday",
    "Location": "1480 Queen St W, Toronto, ON M6K 1M4",
    "Food/Beverage": "Hot Coffe",
    "Quantity": 1,
    "Amount": "$2.90",
    "Mode": "Driven In"
  },
  {
    "Icon": "burger",
    "Date": "04/03/2024",
    "Time": "12:30 PM",
    "Day of the week": "Monday",
    "Location": "1167 Younge St, Toronto, ON M6J 1J4",
    "Food/Beverage": "Burger",
    "Quantity": 1,
    "Amount": "$5.54",
    "Mode": "Walk In"
  },
  {
    "Icon": "coffee",
    "Date": "04/03/2024",
    "Time": "12:30 PM",
    "Day of the week": "Monday",
    "Location": "1167 Younge St, Toronto, ON M6J 1J4",
    "Food/Beverage": "Cold Coffee",
    "Quantity": 1,
    "Amount": "$3.20",
    "Mode": "Walk In"
  },
  {
    "Icon": "coffee",
    "Date": "05/03/2024",
    "Time": "7:55 AM",
    "Day of the week": "Tuesday",
    "Location": "1480 Queen St W, Toronto, ON M6K 1M4",
    "Food/Beverage": "Hot Coffe",
    "Quantity": 1,
    "Amount": "$2.90",
    "Mode": "Driven In"
  },
  {
    "Icon": "pizza",
    "Date": "05/03/2024",
    "Time": "12:45 PM",
    "Day of the week": "Tuesday",
    "Location": "1167 Younge St, Toronto, ON M6J 1J4",
    "Food/Beverage": "Pizza",
    "Quantity": 1,
    "Amount": "$6.65",
    "Mode": "Walk In"
  },
  {
    "Icon": "drink",
    "Date": "05/03/2024",
    "Time": "12:45 PM",
    "Day of the week": "Tuesday",
    "Location": "1167 Younge St, Toronto, ON M6J 1J4",
    "Food/Beverage": "Lemonade",
    "Quantity": 2,
    "Amount": "$7.20",
    "Mode": "Walk In"
  },
  {
    "Icon": "coffee",
    "Date": "05/03/2024",
    "Time": "3:30 PM",
    "Day of the week": "Tuesday",
    "Location": "171 E Liberty St, Toronto, ON M6K 3P6",
    "Food/Beverage": "Hot Coffe",
    "Quantity": 1,
    "Amount": "$2.90",
    "Mode": "Driven In"
  },
  {
    "Icon": "coffee",
    "Date": "06/03/2024",
    "Time": "8:01 AM",
    "Day of the week": "Wednesday",
    "Location": "1480 Queen St W, Toronto, ON M6K 1M4",
    "Food/Beverage": "Hot Coffe",
    "Quantity": 1,
    "Amount": "$2.90",
    "Mode": "Driven In"
  },
  {
    "Icon": "pizza",
    "Date": "06/03/2024",
    "Time": "12:56 PM",
    "Day of the week": "Wednesday",
    "Location": "1167 Younge St, Toronto, ON M6J 1J4",
    "Food/Beverage": "Pizza",
    "Quantity": 1,
    "Amount": "$6.65",
    "Mode": "Walk In"
  },
  {
    "Icon": "coffee",
    "Date": "06/03/2024",
    "Time": "12:56 PM",
    "Day of the week": "Wednesday",
    "Location": "1167 Younge St, Toronto, ON M6J 1J4",
    "Food/Beverage": "Cold Coffee",
    "Quantity": 1,
    "Amount": "$3.20",
    "Mode": "Walk In"
  },
  {
    "Icon": "coffee",
    "Date": "07/03/2024",
    "Time": "7:51 AM",
    "Day of the week": "Thursday",
    "Location": "1480 Queen St W, Toronto, ON M6K 1M4",
    "Food/Beverage": "Hot Coffe",
    "Quantity": 1,
    "Amount": "$2.90",
    "Mode": "Driven In"
  },
  {
    "Icon": "coffee",
    "Date": "08/03/2024",
    "Time": "7:59 AM",
    "Day of the week": "Friday",
    "Location": "1480 Queen St W, Toronto, ON M6K 1M4",
    "Food/Beverage": "Hot Coffe",
    "Quantity": 1,
    "Amount": "$2.90",
    "Mode": "Driven In"
  },
  {
    "Icon": "burger",
    "Date": "08/03/2024",
    "Time": "1:05 PM",
    "Day of the week": "Friday",
    "Location": "1167 Younge St, Toronto, ON M6J 1J4",
    "Food/Beverage": "Burger",
    "Quantity": 1,
    "Amount": "$5.54",
    "Mode": "Walk In"
  },
  {
    "Icon": "coffee",
    "Date": "08/03/2024",
    "Time": "1:05 PM",
    "Day of the week": "Friday",
    "Location": "1167 Younge St, Toronto, ON M6J 1J4",
    "Food/Beverage": "Cold Coffee",
    "Quantity": 2,
    "Amount": "$6.40",
    "Mode": "Walk In"
  }
];
