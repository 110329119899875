// useLogout.js or within your hooks file
import { useNavigate } from 'react-router-dom';

const API_GATEWAY = process.env.REACT_APP_API_GATEWAY_URL;

const useLogout = () => {
  const navigate = useNavigate();

  const logout = async () => {
    try {
      // Call the logout endpoint
      const response = await fetch(API_GATEWAY + '/auth/logout', {
        method: 'POST',
        credentials: 'include' // Important for sending the cookie
      });

      if (response.ok) {
        // Optionally clear any client-side state related to authentication here
                
        // Then navigate to the sign-in page or wherever appropriate
        navigate('/auth');
      } else {
        // Handle any errors, maybe set an error state
        console.error('Logout failed');
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return logout;
};

export default useLogout;
