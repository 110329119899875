import { useCallback, useState } from 'react';
import { Button, Snackbar, Tooltip } from '@mui/material';
import { analytics } from '../../../analytics';

export const SegmentButton = ({ label, eventType }) => {
  const [showToast, setShowToast] = useState();

  const triggerSegmentEvent = useCallback(() => {
    analytics.track(eventType).then(() => setShowToast(true));
  }, [eventType]);

  return (
    <>
      <Tooltip title="⚠️ Your adblocker *must* be disabled">
        <Button variant="contained" onClick={triggerSegmentEvent} sx={{ marginTop: "10px" }}>{label}</Button>
      </Tooltip>
      <Snackbar
        open={showToast}
        onClose={() => setShowToast(false)}
        autoHideDuration={3000}
        message="Event triggered"
      />
    </>
  );
};
