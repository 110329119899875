import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

const API_GATEWAY = process.env.REACT_APP_API_GATEWAY_URL;

export const ProtectedRoute = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const verifyAuth = async () => {
      try {
        // Use the Fetch API with credentials included for cookie handling
        const response = await fetch(API_GATEWAY + '/auth/status', {
          method: 'GET',
          credentials: 'include' // Essential for including cookies in cross-origin requests
        });
              
        // Check if the response is OK (status in the range 200-299)
        if (response.ok) {
          setIsAuthenticated(true);
        } else {
          throw new Error('Authentication check failed');
        }
      } catch (error) {
        console.error('Authentication error:', error);
        setIsAuthenticated(false);
      } finally {
        setIsLoading(false);
      }
    };

    verifyAuth();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>; // Or any loading spinner
  }

  return isAuthenticated ? children : <Navigate to="/auth" />;
};
