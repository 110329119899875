export const userActionCodeSnippet = `import requests

# The URL for the API endpoint you want to fetch data from
url = 'https://api.example.com/data'
response = requests.get(url)
# Perform a GET request
# Check if the request was successful
if response.status_code == 200:
    # Parse the JSON response
    data = response.json()
    print(data)
else:
    print(f'Failed to fetch data: HTTP {response.status_code}')
`;
