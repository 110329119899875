import CoffeeIcon from '@mui/icons-material/Coffee';
import LunchDiningIcon from '@mui/icons-material/LunchDining';
import LocalPizzaIcon from '@mui/icons-material/LocalPizza';
import LocalDrinkIcon from '@mui/icons-material/LocalDrink';

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export async function fetchOpenAI(data) {
  const OPENAI_KEY = process.env.REACT_APP_OPENAI_API_KEY;

  const response = await fetch("https://api.openai.com/v1/chat/completions", {
    method: "POST",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${OPENAI_KEY}` 
    },
    body: JSON.stringify(data)
  });
  return await response.json();
}

export function renderSwitch(value) {
  switch(value["Icon"]) {
  case 'coffee':
    return <CoffeeIcon />;
  case 'burger':
    return <LunchDiningIcon />;
  case 'pizza':
    return <LocalPizzaIcon />;
  case 'drink':
    return <LocalDrinkIcon />;                     
  default:
    return <CoffeeIcon />;
  }
}
  
