import "@fontsource/lato";
import { AppBar, Box, Container, ThemeProvider, Toolbar, Typography, createTheme } from '@mui/material';
import Stack from "@mui/material/Stack";
import Grid from '@mui/material/Unstable_Grid2';
import { useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import { analytics } from '../../analytics';
import { InstantRewards, Offers, Prompts, SegmentButton, Typewriter } from "./components";
import { userActionCodeSnippet } from './userActionCodeSnippet';

const Hackathon = () => {
  const theme = createTheme({
    typography: {
      fontFamily: 'Lato, Roboto, sans-serif',
      h6: {
        fontWeight: 600
      }
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: '#ffffff'
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            padding: '16px 24px 14px',
            borderRadius: 0,
            fontSize: 16,
            minWidth: 175,
            backgroundColor: '#121212',
            '&:hover': {
              backgroundColor: '#595959'
            },
            marginRight: 24
          }
        }
      }
    }
  });

  useEffect(() => {
    let uuid = uuidv4();
    analytics.identify(uuid);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="static">
        <Toolbar>
          <img alt="OctanceAI logo" src="/logo.svg" component="div" height="70px" />
        </Toolbar>
      </AppBar>
      <>
        {/* Header */}
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "60vh", backgroundColor: "black", color: "white" }}
        >
          <Typography variant="h5" gutterBottom>
          OctanceAI
          </Typography>
          <Typography variant="h2" gutterBottom>
          How It Works
          </Typography>
        </Stack>

        {/* Track User Actions */}
        <Container sx={{ marginTop: -12, padding: 12, backgroundColor: "white" }}>
          <Typography fontWeight="600" variant="h6" gutterBottom>
            Track Any User Actions
          </Typography>
          <Typography variant="body1" marginBottom="32px">
            Description here
          </Typography>
          <SegmentButton label="Sign Up" eventType="signup" />
          <SegmentButton label="Purchase" eventType="purchase" />
          <SegmentButton label="Open app" eventType="app_interaction" />
          <Box sx={{ marginTop: "48px", paddingTop: "36px", paddingBottom: "24px", width: "100%", height: "125px", backgroundColor: "black" }}>
            <Typewriter text={userActionCodeSnippet} />
          </Box>
        </Container>

        {/* Give Instant Rewards */}
        <Box sx={{ marginTop: 12, marginBottom: 12 }}>
          {InstantRewards()}
        </Box>
          
        {/* Offers and Prompts */}
        <Container sx={{ marginTop: 12, marginBottom: 12 }}>
          <Grid container>
            <Grid container item xs={12} md={6} sx={{ marginTop: 12, marginBottom: 12, border: '1px solid black' }}>
              {Offers()}
            </Grid>

            <Grid container item xs={12} md={6} sx={{ marginTop: 12, marginBottom: 12, border: '1px solid black' }}>
              {Prompts()}
            </Grid>
          </Grid>
        </Container>
      </>
    </ThemeProvider>
  );
};

export default Hackathon;
