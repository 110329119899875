export const onboardingCodeSnippet = `

brew install httpie

https POST dev.octance.ai/partners/register email=info@octance.ai phone_number=1231234343
{
    "api_key": "UCXXMkdhT0TuSYZ16WcBnCYXgPl-CSPbzcSYsOGv_TA",
    "partner_id": "b3715d5b-5909-4fc3-b8a4-cd8f224922ca"
}

https POST dev.octance.ai/events X-API-Key:UCXXMkdhT0TuSYZ16WcBnCYXgPl-CSPbzcSYsOGv_TA < event.json
{
    "event_id": "5a91d0a6-9fca-4eb0-bfda-8014c3b3abc1",
    "message": "Processed push event",
    "status": "success"
}

https GET dev.octance.ai/partners/github/octance/popo/balance X-API-Key:UCXXMkdhT0TuSYZ16WcBnCYXgPl-CSPbzcSYsOGv_TA
{
    "balance": 100
}
    
echo "Dev Portal":            
https://dev.octance.ai/docs

echo "Learn more about Octance":            
https://octance.ai

echo "Join our Discord community" 
https://octance.ai/discord
`;
