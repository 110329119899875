import { Container, Typography } from '@mui/material';

export const Offers = () => {
  return (
    <Container sx={{ padding: 12 }}>
      <Typography fontWeight="600" variant="h6" gutterBottom>
        AI Generated Offers
      </Typography>
      <Typography variant="body1" marginBottom="32px">
        Users will get points from doing defined actions.
      </Typography>
    </Container>
  );
};
