import "@fontsource/lato";
import { AppBar, Box, Container, ThemeProvider, Toolbar, createTheme } from '@mui/material';
import Stack from "@mui/material/Stack";
import ReactPrismjs from '@uiw/react-prismjs';
import 'prismjs/components/prism-bash';

import { useEffect, useState } from "react";
import ReactRotatingText from 'react-rotating-text';
import { v4 as uuidv4 } from 'uuid';
import { analytics } from '../../analytics';
import OctanceGuide from "./octanceGuide";
import { onboardingCodeSnippet } from "./onboardingCodeSnippet";

const Readme = () => {
  const theme = createTheme({
    typography: {
      fontFamily: 'Lato, Roboto, sans-serif',
      h6: {
        fontWeight: 600
      }
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: '#ffffff'
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            padding: '16px 24px 14px',
            borderRadius: 0,
            fontSize: 16,
            minWidth: 175,
            backgroundColor: '#121212',
            '&:hover': {
              backgroundColor: '#595959'
            },
            marginRight: 24
          }
        }
      }
    }
  });
  const [firstTime,setFirstTime] = useState(true);  
  useEffect(() => {
    let uuid = uuidv4();
    analytics.identify(uuid);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="static">
        <Toolbar>
          <img alt="OctanceAI logo" src="/logo.svg" component="div" height="70px" />
        </Toolbar>
      </AppBar>
      <>
        {/* Header */}
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "10vh", backgroundColor: "white", color: "white" }}
        >
          {firstTime && <span className="attractionTagReadme"><ReactRotatingText onTypingEnd={()=>setFirstTime(false)} items={['Empower moments into value']} /></span>}
          {!firstTime && <span className="attractionTagReadme">Empower moments into value</span>}
        </Stack>

        {/* Starting point */}
        <Container sx={{ backgroundColor: "white" }}>
          <Box>
            <ReactPrismjs language="bash" source={onboardingCodeSnippet} />
          </Box>
        </Container>
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ backgroundColor: "white", color: "white" }}
        >
          <span className="attractionTagReadme">Why Octance?</span>          
        </Stack>
        <OctanceGuide />
      </>
    </ThemeProvider>
  );
};

export default Readme;


