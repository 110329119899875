import React, { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logo from "./logo.svg";
import { Alert, LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';


const defaultTheme = createTheme();
const API_GATEWAY = process.env.REACT_APP_API_GATEWAY_URL;

export default function Auth() {
  const [submitted, setSubmitted] = useState(false);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const cookies = document.cookie;
        console.log('Cookies:', cookies);
        const response = await fetch(API_GATEWAY + '/auth/status', {
          method: 'GET',
          credentials: 'include'  // Necessary for cookies if you're using HTTP-only cookies for auth
        });

        if (response.ok) {
          // If the response is successful, the user is already authenticated
          navigate('/dashboard'); // Redirect them to the dashboard or other protected route
        } else {
          // Handle not authenticated status
          // e.g., Stay on the login page or show a login form
          console.log("User not authenticated. Proceed with login.");
        }
      } catch (error) {
        console.error("Error checking authentication status:", error);
        // Handle error (e.g., show error message)
      } finally {
        setIsLoading(false);
      }
    };

    checkAuthStatus();
  }, [navigate]);

  function createUser(email){

    fetch(API_GATEWAY + "/auth/magic-link", {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ "email_address": email,"roles": [] }) }).then((responseJson) => {
      console.log(responseJson);
    }).catch((error) => {
      console.log(error);
    });;
  }
  const handleSubmit = (event) => {
    setSubmitted(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email')
    });
    createUser(data.get('email'));
  };
  if (isLoading) {
    return <div>Loading...</div>; // Show a loading spinner or message while checking auth status
  }
  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(https://source.unsplash.com/random?wallpapers)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <img className="App-logo" alt="Octance" src={logo} />
            <Typography component="h1" variant="h5">
              Authentication
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                disabled={submitted}
              />
              <LoadingButton loading={submitted} variant="outlined" type="submit"
                fullWidth
                sx={{ mt: 3, mb: 2 }}>
              Submit
              </LoadingButton>              
              {submitted && <Alert hidden severity="info">
              Check your mail box to continue...
              </Alert>}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
