import { Box, Container, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

export const InstantRewards = () => {
  return (
    <Container sx={{ padding: 12 }}>
      <Typography fontWeight="600" variant="h6" gutterBottom>
            Give Instant Rewards
      </Typography>
      <Typography variant="body1" marginBottom="32px">
            Users will get points from doing defined actions.
      </Typography>
      <Box sx={{ marginTop: 4, marginBottom: 4, padding: 8, backgroundColor: "black", color: "white" }}>
        <Typography variant="h4">◈ 6900 pts</Typography>
        <Typography variant="h6" fontWeight="normal">+600 pts this month</Typography>
      </Box>
      <Grid marginTop="20px" container spacing={3}>
        <Grid item md={3}>
          <Box sx={{ 
            backgroundColor: 'black',
            color: 'white',
            padding: '40px 30px',
            display: 'flex',
            justifyContent: 'space-between'
          }}>
            <Typography variant="h5">Sign up</Typography>
            <Typography variant="h5">10 pts</Typography>
          </Box>
        </Grid>

        <Grid item md={3}>
          <Box sx={{ 
            backgroundColor: 'black',
            color: 'white',
            padding: '40px 30px',
            display: 'flex',
            justifyContent: 'space-between'
          }}>
            <Typography variant="h5">Browse</Typography>
            <Typography variant="h5">10 pts</Typography>
          </Box>
        </Grid>

        <Grid item md={4}>
          <Box sx={{ 
            backgroundColor: 'black',
            color: 'white',
            padding: '40px 30px',
            display: 'flex',
            justifyContent: 'space-between'
          }}>
            <Typography variant="h5">Make purchase</Typography>
            <Typography variant="h5">100 pts</Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};
