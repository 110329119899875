import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { Button, CardMedia } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import offerPicture from "./offer.png";
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import DOMPurify from "dompurify";
import parse from "html-react-parser";
import { transactions } from './coffeeTransaction';
import { fetchOpenAI,a11yProps,renderSwitch } from './utils';
import { CustomTabPanel } from './customTabPanel';
import { Item } from './styledItem';
export default function Demo() {
  const [value, setValue] = React.useState(0);
  const [generated, setGenerated] = React.useState(false);
  const [offer, setOffer] = React.useState("");
  const [timeYouWant, setTimeYouWant] = React.useState(new Date().toLocaleTimeString("en-GB"));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const generateEvent = async () => {
    const data = {
      "model": "gpt-4-turbo-preview",
      "messages": [
        {
          "role": "system",
          "content": `you are a beautiful and smart data analyst that can aggregate and cluster data`
        },
        {
          "role": "assistant",
          "content": `Here is the dataset ${JSON.stringify(transactions)}`
        },
        {
          "role": "user",
          "content": `can you generate the best TEXT offer we can have at ${timeYouWant} with a location based on the time of the day for this customer with no explanation. Just send the offer based on your analyse and the time of the day`
        }
      ]
    };

    const content = await fetchOpenAI(data);
    const message = content.choices[0].message.content;
    setOffer(DOMPurify.sanitize(message, { USE_PROFILES: { html: true } }));
    setGenerated(true);
  };

  return (
    <Stack spacing={2}>
      <Item>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Final Customer - a Coffee Shop" {...a11yProps(0)} />          
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <List sx={{ width: '100%', maxWidth: 500, bgcolor: 'background.paper',position: 'relative', overflow: 'auto',maxHeight: 300 }}>
              {transactions.map((value, index) => {                         
                return <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      {renderSwitch(value)}                                    
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={value["Food/Beverage"] + " " + value["Amount"] + " " + value["Mode"]} secondary={value["Date"] + ":" + value["Time"] + "   " + value["Location"]} />
                </ListItem>;   
              })}
            </List>
          </CustomTabPanel>
        </Box>
      </Item>
      <Item>
        <TimePicker onChange={setTimeYouWant} value={timeYouWant}            
          amPmAriaLabel="Select AM/PM"
          clearAriaLabel="Clear value"
          clockAriaLabel="Toggle clock"
          hourAriaLabel="Hour"
          maxDetail="second"
          minuteAriaLabel="Minute"
          nativeInputAriaLabel="Time"
          format="HH:mm:ss a" />
      </Item>
      <Item>
        <Button variant="outlined" onClick={generateEvent}>Generate Offers</Button>
      </Item>
      <Item>
        { generated &&
            <Card sx={{ maxWidth: 345 }}>
            	<CardMedia
            		sx={{ height: 140 }}
            		image={offerPicture}
            		title="Offer"
            	/>
            	<CardContent>
            		<Typography gutterBottom variant="h5" component="div">
                    Generated Offer
            		</Typography>
            		<Typography variant="body2" color="text.secondary">
            			{parse(offer)}
            		</Typography>
            	</CardContent>        
            </Card>}
      </Item>
    </Stack>
  );
}
