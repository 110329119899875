import { Container, Typography } from '@mui/material';

export const Prompts = () => {
  return (
    <Container sx={{ padding: 12 }}>
      <Typography fontWeight="600" variant="h6" gutterBottom>
        Asking prompts
      </Typography>
      <Typography variant="body1" marginBottom="32px">
        Description here
      </Typography>
    </Container>
  );
};
