import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './index.css';
import Auth from './pages/auth/auth';
import Dashboard from './pages/dashboard/dashboard';
import Demo from './pages/demo/demo';
import Hackathon from './pages/hackathon/Hackathon';
import Home from './pages/home/home';
import Layout from './pages/layout/layout';
import Readme from './pages/readme/Readme';
import { ProtectedRoute } from './protectedRoute';
import reportWebVitals from './reportWebVitals';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="demo" element={<Demo />} />
          <Route path="auth" element={<Auth />} />
          <Route path="hackathon" element={<Hackathon />} />
          <Route path="readme.md" element={<Readme />} />
          <Route 
            path="dashboard" 
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            } />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
  
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
