import { Button, TextField, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import { useState } from "react";
import useLogout from './logout';
import ReactJson from "@microlink/react-json-view";

const defaultTheme = createTheme();
const API_GATEWAY = process.env.REACT_APP_API_GATEWAY_URL;
const Dashboard = () => {
  const [sandboxKey] = useState("");
  var [account,setAccount] = useState(null);
  var [balance,setBalance] = useState(null);
  const [event, setEvent] = useState({
    "account_id": "uuid",
    "merchant_name": "Shark",
    "merchant_category": "cartoon",
    "merchant_address": "",
    "transaction_amount": "",
    "transaction_time": ""
  });

  async function sendEvent(){
    try {
      // Call the POST /events endpoint
      await fetch(API_GATEWAY + '/events', {
        method: 'POST',
        credentials: 'include', // Important for sending the cookie
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },              
        body: JSON.stringify(event)
      });                        
    } catch (error) {
      console.error('Error during POST events:', error);
    }
  }
  async function generateUser()  {
    try {
      // Call the POST accounts endpoint
      const response = await fetch(API_GATEWAY + '/accounts', {
        method: 'POST',
        credentials: 'include', // Important for sending the cookie
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },              
        body: JSON.stringify({
          "email_address": "user" + Math.floor(Math.random() * 99999999) + "@example.com",
          "roles": [
            "customer"
          ]
        })
      });
      const result = await response.json();
      setAccount(result);
    } catch (error) {
      console.error('Error during POST accounts:', error);
    }
  };

  setInterval(async () => {
    try {
      if(account !== null){
        // Call the GET accounts endpoint
        const response = await fetch(API_GATEWAY + '/accounts/' + account.email_address, {
          method: 'GET',
          credentials: 'include', // Important for sending the cookie
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });
        const result = await response.json();
        setBalance(result);
      }
    } catch (error) {
      console.error('Error during GET account:', error);
    }
  }, 60000);
        
  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Typography component="h1" variant="h5">
                 Welcome to the dashboard
          </Typography>
        </Grid>
        <Grid xs={2}>
          <Button variant="outlined" color="error" onClick={useLogout()}>logout</Button>
        </Grid>
        <Grid xs={2}>
          <Button variant="contained" color="success">sandbox Key</Button>
        </Grid>
        <Grid xs={2}>
          <Button variant="contained" color="success" onClick={generateUser} >Generate random user</Button>
        </Grid>
        <Grid xs={2}>
          {sandboxKey !== "" && <TextField  disabled id="outlined-basic" label="Sandbox Key" variant="outlined" />}
        </Grid>
        <Grid xs={6}>
          {account && <ReactJson src={account} theme="monokai" />}
        </Grid>
        <Grid xs={6}>
          {balance &&  <Typography variant="h5" component="h2">settled_balance:{balance.settled_balance}</Typography>}
          {balance &&  <Typography variant="h5" component="h2">in_progress_balance:{balance.in_progress_balance}</Typography>}
        </Grid>
        <Grid xs={6}>
          {account && <ReactJson src={event} theme="monokai" onEdit={data=>setEvent(data.updated_src)}  />}
        </Grid>
        <Grid xs={2}>
          {account && <Button variant="contained" onClick={sendEvent} color="success">Send event</Button>}
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Dashboard;
