import {
  Box,
  Container,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography
} from '@mui/material';
import React from 'react';

const OctanceGuide = () => {
  return (
    <Container>
      <Paper elevation={3} sx={{ padding: 4, marginY: 4 }}>
        <Typography variant="h5" gutterBottom>
          Overview
        </Typography>
        <Typography paragraph>
        Octance is the AI-powered B2B loyalty platform that transforms your customer data into actionable insights, without any integration hassle. By connecting directly to tools like Segment and Mixpanel, we instantly analyze your data to predict customer behavior, optimize offers, and personalize engagement strategies. Octance helps you boost retention, increase customer lifetime value, and identify your next best clients - all through an easy-to-use interface backed by powerful AI. With Octance, you're not just running a loyalty program; you're leveraging AI to build stronger, more profitable B2B relationships.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Key Features
        </Typography>
        <List>
          {[
            {
              title: 'Zero Integration',
              items: [
                'Connects directly to Segment, Mixpanel, and similar tools',
                'No integration work required on client side'
              ]
            },
            {
              title: 'AI-Driven Insights',
              items: [
                'Creates dynamic customer personas',
                'Analyzes transaction patterns and behaviors'
              ]
            },
            {
              title: 'Predictive Offer Optimization',
              items: [
                'Simulates offer performance before deployment',
                'Tailors promotions for maximum impact'
              ]
            },
            {
              title: 'Vector Similarity Search',
              items: [
                'Identifies potential high-value clients',
                'Matches based on complex behavior patterns'
              ]
            },
            {
              title: 'Real-Time Sentiment Analysis',
              items: [
                'Tracks emotional context of interactions',
                'Enables responsive communication strategies'
              ]
            },
            {
              title: 'Scalable Architecture',
              items: [
                'Built on Weaviate vector database and TigerBeetle ledger',
                'Maintains performance as data volume grows'
              ]
            },
            {
              title: 'Developer-Friendly CLI',
              items: [
                'Simplifies testing and customization',
                'Enhances platform extensibility'
              ]
            },
            {
              title: 'Ethical AI and Data Governance',
              items: [
                'Ensures responsible data handling',
                'Builds trust with B2B clients'
              ]
            }
          ].map((feature, index) => (
            <Box key={index} marginBottom={2}>
              <Typography variant="h6">{feature.title}</Typography>
              <List dense>
                {feature.items.map((item, itemIndex) => (
                  <ListItem key={itemIndex}>
                    <ListItemText primary={item} />
                  </ListItem>
                ))}
              </List>
            </Box>
          ))}
        </List>

        <Typography variant="h5" gutterBottom>
          How It Works
        </Typography>
        <List>
          {[
            'Connect Octance to your existing customer metrics tools',
            'Octance immediately starts analyzing your B2B customer data',
            'Receive AI-generated insights and personalized offer suggestions',
            "Implement targeted loyalty strategies based on Octance's recommendations",
            'Monitor real-time performance and adjust tactics as needed'
          ].map((step, index) => (
            <ListItem key={index}>
              <ListItemText primary={`${index + 1}. ${step}`} />
            </ListItem>
          ))}
        </List>

        <Typography variant="h5" gutterBottom>
          Benefits
        </Typography>
        <List>
          {[
            'Instant deployment with no integration overhead',
            'Deep, AI-driven understanding of B2B clients',
            'Highly personalized and effective loyalty programs',
            'Improved client retention and acquisition',
            'Data-driven decision making for B2B relationships'
          ].map((benefit, index) => (
            <ListItem key={index}>
              <ListItemText primary={benefit} />
            </ListItem>
          ))}
        </List>

        <Typography variant="h5" gutterBottom>
          Who It's For
        </Typography>
        <List>
          {[
            'B2B companies looking to enhance customer loyalty',
            'Businesses using customer metrics tools like Segment or Mixpanel',
            'Organizations seeking advanced, AI-powered customer insights',
            'Companies wanting to optimize their B2B offer strategies'
          ].map((audience, index) => (
            <ListItem key={index}>
              <ListItemText primary={audience} />
            </ListItem>
          ))}
        </List>
      </Paper>
    </Container>
  );
};

export default OctanceGuide;
